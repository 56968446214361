// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-upload {
}

.w-10 {
  width: 10% !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/profile.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".image-upload {\n}\n\n.w-10 {\n  width: 10% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
