import React from 'react';

const NotFound = () => {
  return (
    <div className='container' style={{ height: '50vh' }}>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
