import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { getAlgorithm } from '../services/algorithmService.js';

import { Link, NavLink } from 'react-router-dom';

const AlgorithmEntry = ({ dataKey, value }) => {
  const [renderAsUl, setRenderAsUl] = useState(false);

  useEffect(() => {
    if (typeof value === 'object') {
      setRenderAsUl(true);
    }
  }, [value]);

  return (
    <div className='h6 my-3 ml-2'>
      <span>{dataKey}:</span>
      {renderAsUl ? (
        <ul className='ml-2'>
          {value.map((v) => {
            return <li key={v}>{v}</li>;
          })}
        </ul>
      ) : (
        <span className='ml-1 text-muted'>{value}</span>
      )}
    </div>
  );
};

const AlgorithmDetail = ({ user, match, algorithmDataModel }) => {
  const [algorithm, setAlgorithm] = useState({
    author: { _id: '' },
  });
  const [displayProfileLink, setDisplayProfileLink] = useState(false);
  useEffect(() => {
    getAlgorithm(match.params.id).then((response) => {
      setAlgorithm(response.data);
      setDisplayProfileLink(response.data.author.name !== 'Unknown');
    });
  }, []);

  const objectEmpty = (object) => {
    return Object.keys(object).length === 0;
  };

  return objectEmpty(algorithmDataModel) ? (
    <div>Loading...</div>
  ) : (
    <section className='container'>
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex'>
            <div className='h3 blue-text'>{algorithm.algorithmName}</div>
            {user && user.oid === algorithm.author._id && (
              <Link className='btn btn-register ml-auto' to={`/algorithm/${algorithm._id}/edit`}>
                Bewerken
              </Link>
            )}
          </div>
          <span className='text-muted'>{`Toegevoegd op: ${new Date(
            algorithm.timestamp,
          ).toLocaleString()} door: `}</span>
          {displayProfileLink ? (
            <NavLink to={`/profile/${algorithm.author._id}`}>{`${algorithm.author.name}`}</NavLink>
          ) : (
            <span>{algorithm.author.name}</span>
          )}
        </div>
        <Tabs className=''>
          <Tab eventKey='About' title='About'>
            <div key={algorithm._id} className='card' style={{ minHeight: '250px' }}>
              <div className='body px-2'>
                <AlgorithmEntry dataKey='Gemaakt door' value={algorithm.developerName} />
                <AlgorithmEntry dataKey='Actief in' value={algorithm.activeHospital} />
                <AlgorithmEntry dataKey='Specialisme' value={algorithm.medicalSpecialism} />

                <div className='h6 my-3 ml-2'>Beschrijving:</div>
                <p className='ml-2'>{algorithm.description}</p>

                <div className='h5 blue-text'>Algoritme details:</div>
                <AlgorithmEntry
                  dataKey={algorithmDataModel.algorithmFunction.displayName}
                  value={algorithm.algorithmFunction}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.algorithmType.displayName}
                  value={algorithm.algorithmType}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.benefitClaims.displayName}
                  value={algorithm.benefitClaims}
                />

                <div className='h5 blue-text'>Doel en gebruik:</div>
                <AlgorithmEntry
                  dataKey={algorithmDataModel.intendedPurpose.displayName}
                  value={algorithm.intendedPurpose}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.applicationGoal.displayName}
                  value={algorithm.applicationGoal}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.intendedUser.displayName}
                  value={algorithm.intendedUser}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.targetPopulation.displayName}
                  value={algorithm.targetPopulation}
                />

                <div className='h5 blue-text'>Ontwikkeling:</div>
                <AlgorithmEntry
                  dataKey={algorithmDataModel.medicalDevice.displayName}
                  value={algorithm.medicalDevice ? 'Ja' : 'Nee'}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.riskClass.displayName}
                  value={algorithm.riskClass}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.primaryDeveloper.displayName}
                  value={algorithm.primaryDeveloper}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.nameCommercialProduct.displayName}
                  value={algorithm.nameCommercialProduct}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.peopleInvolved.displayName}
                  value={algorithm.peopleInvolved}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.lessonsLearned.displayName}
                  value={algorithm.lessonsLearned}
                />

                <div className='h5 blue-text'>Data, fase en platform:</div>
                <AlgorithmEntry
                  dataKey={algorithmDataModel.technicalReadinessLevel.displayName}
                  value={algorithm.technicalReadinessLevel}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.dataSource.displayName}
                  value={algorithm.dataSource}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.technicalPlatform.displayName}
                  value={algorithm.technicalPlatform}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.dataStandard.displayName}
                  value={algorithm.dataStandard}
                />
                <AlgorithmEntry
                  dataKey={algorithmDataModel.innovationFunnelTested.displayName}
                  value={algorithm.innovationFunnelTested}
                />

                {/* {Object.keys(algorithmDataModel).map(k=>{return (
                            <div>
                                <span className="h6 my-3 mr-3">{`${k}`}</span>
                                <span>{`${algorithm[k]}`}</span>
                            </div>
                        )})} */}
              </div>
            </div>
          </Tab>
          <Tab eventKey='Media' title={`Media`}>
            <div key={algorithm._id} className='card' style={{ minHeight: '250px' }}>
              <div className='body p-2'>
                <div className='h5 mt-3'>Publications</div>
                <ul>
                  <li>
                    <a href={algorithm.mediaURL}>{algorithm.mediaURL}</a>
                  </li>
                </ul>
                <div className='h5 mt-3'>Media</div>
                <ul>
                  <li>
                    <a href={algorithm.mediaURL}>{algorithm.mediaURL}</a>
                  </li>
                </ul>
              </div>
              <div className='footer mt-5 ml-2'></div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default AlgorithmDetail;
