import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { getAlgorithms } from '../../services/algorithmService';

const BarChart = ({ label, data, algorithms, dataKey }) => {
  if (label === 'Medisch hulpmiddel') {
    data = [true, false];
  }
  
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        precision: 0,
        ticks: {
          stepSize: 1, // show whole numbers only
        },
      },
    },
    plugins: {
      legend: {
        display: false, // hide legend
      },
    },
  }; 
  const dataset = {
    labels: data,
    datasets: [
      {
        label: label,
        data: data.map((value) => algorithms.filter((algo) => algo[dataKey] == value).length),
        borderWidth: 0,
        backgroundColor: 'rgb(13, 110, 253)',
        borderColor: 'rgb(13, 110, 253)',
      },
    ],
  };

  return (
    <div className="card my-2 block-shadow">
      <div className="card-header text-uppercase blue-block">
        <div className="d-flex">
          <div className="py-1">{label}</div>
        </div>
      </div>

      <div className="card-body">
        <Bar data={dataset} options={options} />
      </div>
    </div>
  );
};

const DashBoard = ({ algorithmDataModel }) => {
  const [algorithms, setAlgorithms] = useState([]);
  // const [algorithmDataModel, setAlgorithmDataModel] = useState({});

  const [uniqueDevelopers, setUniqueDevelopers] = useState([]);
  const [developingHospitals, setDevelopingHospitals] = useState([]);

  useMemo(() => {
    function registerCharts() {
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Colors,
      );
    }
    registerCharts();
  }, []);

  useEffect(() => {
    function loadAlgorithms() {
      return new Promise((resolve, reject) => {
          getAlgorithms()
          .then(response=>resolve(response.data))          
          .catch (err=>reject(err))
          })
    }
    loadAlgorithms()
      .then(algorithms => {        
        setAlgorithms(algorithms);

        setUniqueDevelopers([...new Set(algorithms.map((algo) => algo.developerName))]);
        setDevelopingHospitals([...new Set(algorithms.map((algo) => algo.activeHospital))]);
      })
      .catch((err) => console.log(err));
  }, []);
  
  
 
  return Object.keys(algorithmDataModel).length === 0 ? (
    <div>Loading...</div>
  ) : (
    <>
      <div className='w-100'>
        <div className='d-flex justify-content-around'>
          <div className='d-flex flex-column align-items-center'>
            <div className='h1' title='algorithms in the inventaris'>{`${algorithms.length}`}</div>
            <div className='text-muted' title='algorithms in the inventaris'>
              Geregistreerde algoritmes
            </div>
          </div>
          <div className='d-flex flex-column align-items-center'>
            <div
              className='h1'
              title='algorithms in the inventaris'
            >{`${uniqueDevelopers.length}`}</div>
            <div className='text-muted' title='unique AI developers'>
              Unieke AI developers
            </div>
          </div>
          <div className='d-flex flex-column align-items-center'>
            <div
              className='h1'
              title='algorithms in the inventaris'
            >{`${developingHospitals.length}`}</div>
            <div className='text-muted' title='algorithms in the inventaris'>
              Ziekenhuizen
            </div>
          </div>
        </div>
        <div className='container d-flex flex-wrap'>
          {Object.keys(algorithmDataModel).map((dataKey) => {
            return (
              <div key={dataKey} className='' style={{ minWidth: '50%' }}>
                {algorithmDataModel[dataKey].isFilterable && (
                  <BarChart
                    label={algorithmDataModel[dataKey].displayName}
                    data={algorithmDataModel[dataKey].listData}
                    key={dataKey}
                    dataKey={dataKey}
                    algorithms={algorithms}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DashBoard;
