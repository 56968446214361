import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Icon from './icon.jsx';


const popover = (help) => {
  const renderLink = () => {
    return (
      <a href={help.helpText.helpLink} target="_blank" rel="noopener noreferrer">
        {help.helpText.linkText}
      </a>
    );
  };

  const replaceLinkInText = (text) => {
    const linkIndex = text.helpText.indexOf('{link}');

    if (linkIndex === -1) {
      return text.helpText
    } else {
    const beforeLinkText = text.helpText.slice(0, linkIndex);
    const afterLinkText = text.helpText.slice(linkIndex + '{link}'.length);

    return (
      <>
        {beforeLinkText}
        {renderLink()}
        {afterLinkText}
      </>
    );
    }
  };

  return (
    <Popover id='popover-basic'>
      <Popover.Title as='h3' className='help-popover-title'>
        Help
      </Popover.Title>
      <Popover.Content>
        {replaceLinkInText(help.helpText)}
      </Popover.Content>
    </Popover>
  );
};


const HelpIcon = (help) => {
  return (
    <span>
      <OverlayTrigger trigger='click' placement='right' overlay={popover(help)}>
        <a>
          <Icon
            name={'help-circle'}
            title='Click for help'
            mode='outline'
            style={{ height: '1rem', width: '1rem' }}
          />
        </a>
      </OverlayTrigger>
    </span>
  );
};

export { HelpIcon };
