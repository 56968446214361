import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CoverImage from '../../cover-image.png';

const LoggedOut = () => {
  const history = useHistory();
  return (
    <>
      <div className='page'>
        <div
          className='cover-image'
          style={{
            backgroundImage: `url(${CoverImage})`,
            height: '40vh',
            backgroundSize: 'cover',
            position: 'relative',
          }}
        >
          <div
            className='mask'
            style={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          ></div>
        </div>

        <section className='container mt-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='text-center'>
                <p>Je bent uitgelogd.</p>
                <Button
                  className='btn-lg py-3 px-5 btn-secondary'
                  onClick={() => history.push('/login')}
                >
                  Opnieuw inloggen
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default LoggedOut;
