import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from './common/table';

import _ from 'lodash';

class AlgorithmTable extends Component {
  state = { sortColumn: { path: 'timestamp', order: 'desc' } };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { algorithms } = this.props;
    const { sortColumn } = this.state;

    const sorted = _.orderBy(algorithms, [sortColumn.path], [sortColumn.order]);

    const columns = [
      {
        path: 'algorithmName',
        label: 'Naam',
        content: (algorithm) => (
          <Link to={`/algorithm/${algorithm._id}/detail`}>{algorithm.algorithmName}</Link>
        ),
      },
      {
        path: 'timestamp',
        label: 'Toegevoegd',
        content: (algorithm) => (
          <div>{new Date(algorithm.timestamp).toLocaleDateString('en-nl')}</div>
        ),
      },
      { path: 'technicalReadinessLevel', label: 'Fase' },
    ];

    return (
      <div className='row gutters-sm'>
        <div className='col-12'>
          <div className='card h-100'>
            <div className='card-body'>
              <h6 className='d-flex align-items-center mb-3'>Mijn algoritmes</h6>
              {algorithms.length === 0 ? (
                <div>Geen algoritmes gevonden.</div>
              ) : (
                <Table
                  columns={columns}
                  data={sorted}
                  sortColumn={sortColumn}
                  onSort={this.handleSort}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlgorithmTable;
