import Joi from 'joi-browser';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AlgorithmTable from './algorithmTable.jsx';

import '../css/profile.css';
import auth from '../services/authService';
import { getUserAlgorithms } from '../services/profileService';
import Input from './common/input.jsx';

const useValidateProp = (input, globalSchema) => {
  const name = input.name;
  const value = input.value;
  const obj = { [name]: value };
  const schema = { [name]: globalSchema[name] };
  const { error } = Joi.validate(obj, schema);
  return error ? error.details[0].message : null;
};
const EditableInput = ({ name, value, editMode, handleChange, error }) => {
  return (
    <>
      <div className='col-sm-3'>
        <div className='h6 mb-0'>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      </div>
      {editMode ? (
        <Input
          value={value}
          name={name}
          onChange={handleChange}
          placeholder={`Type your ${name} here..`}
          error={error}
        />
      ) : (
        <div className='col-sm-9 text-secondary'>{value}</div>
      )}
    </>
  );
};

const Profile = ({ match }) => {
  const [userProfileData, setUserProfileData] = useState({
    name: 'Your name',
    imageUrl: '',
    email: 'email',
  });
  const [algorithms, setAlgorithms] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [schema] = useState({
    name: Joi.string().min(5).required().label('Name'),
    email: Joi.string().required().email().label('Email'),
  });

  const isValid = useValidateProp;

  useEffect(() => {
    let submitted = true;
    const { id } = match.params;

    try {
      // const user = await getUserProfile(id);
      getUserAlgorithms(id).then((algorithms) => {
        if(submitted){
          setAlgorithms(algorithms);
          const user = auth.getCurrentUser();
          if (id === user.oid) {
            setUserProfileData(user);
          } else {
            setUserProfileData(algorithms[0].author);
          }
        }
      });
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 404) {
        toast.error(`User doesnt exist.`);
      } else {
        toast.error('An unknown error occured.');
      }
    }
    return ()=>{submitted=false}
  }, [match]);

  const handleChange = ({ currentTarget: input }) => {
    const currentErrors = { ...errors };
    const errorMessage = isValid(input, schema);
    if (errorMessage) {
      currentErrors[input.name] = errorMessage;
    } else {
      delete currentErrors[input.name];
    }
    const temp_data = { ...userProfileData };
    temp_data[input.name] = input.value;
    setUserProfileData(temp_data);
    setErrors(currentErrors);
  };

  return (
    <>
      <div className='container'>
        <div className='main-body'>
          <div className='row gutters-sm'>
            {/* <div className='col-md-4 mb-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <img
                      src={userProfileData.imageUrl}
                      alt='profile-image'
                      className='img-fluid rounded-circle'
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className='col-md-12'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='row'>
                    <EditableInput
                      name='Naam'
                      value={userProfileData.name}
                      editMode={false}
                      handleChange={handleChange}
                      errors={errors['name']}
                    />
                  </div>
                  <hr />
                  <div className='row'>
                    <EditableInput
                      name='E-mailadres'
                      value={userProfileData.email}
                      editMode={false}
                      handleChange={handleChange}
                      errors={errors['email']}
                    />
                  </div>
                </div>
              </div>
              <AlgorithmTable algorithms={algorithms} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
