import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';
import CoverImage from '../../cover-image.png';
import auth from '../../services/authService';
const Login = () => {
  let history = useHistory();
  let location = useLocation();

  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const [error] = useState('');

  // login mechanism
  const startSSOLogin = () => {
    const params = {
      response_type: 'code',
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      scope: 'User.Read',
      // TODO: check if state is a required field and if the value needs to be unique every time
      state: uuidV4(),
    };
    const authUrl = `https://login.microsoftonline.com/${
      process.env.REACT_APP_TENANT_ID
    }/oauth2/v2.0/authorize?${new URLSearchParams(params).toString()}`;
    window.location.href = authUrl;
  };

  const parseUserSSOCode = async () => {
    const code = query.get('code');
    if (code !== null) {
      // handle redirect from microsoft
      const authSuccess = await auth.getUserByCode(code);
      if (authSuccess === true) {
        // send to home page
        history.push('/');
      } else {
        toast.error('An error occurred while logging in.');
      }
    } else {
      // start login to microsoft
      if (auth.getCurrentUser() === null) {
        startSSOLogin();
      } else {
        history.push('/');
      }
    }
  };

  useEffect(() => {
    parseUserSSOCode();
  }, []);

  return (
    <>
      <div className='page'>
        <div className='page'>
          <div
            className='cover-image'
            style={{
              backgroundImage: `url(${CoverImage})`,
              height: '40vh',
              backgroundSize: 'cover',
              position: 'relative',
            }}
          >
            <div
              className='mask'
              style={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            ></div>
          </div>

          <section className='container mt-5'>
            <div className='row'>
              <div className='col-12 text-center'>
                <p>Je wordt ingelogd</p>
                <p className='text-danger'>{error}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Login;
