// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-popover-title{
    background-color:  rgb(26, 26, 168);
    color:white;
}`, "",{"version":3,"sources":["webpack://./src/css/algorithmform.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,WAAW;AACf","sourcesContent":[".help-popover-title{\n    background-color:  rgb(26, 26, 168);\n    color:white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
