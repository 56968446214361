// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-copyrightlink {
  color: black;
  text-decoration: none;
}

.hidden-copyrightlink:hover {
  color: whitesmoke;
}

.strong {
  font-weight: 600;
}

.fab {
  position: fixed;
  right: 5%;
  bottom: 10%;
  z-index: 999;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
}

.blue-text {
  color: rgb(26, 26, 168);
}

.btn-register:hover {
  color: rgb(180, 180, 180);
  background-color: rgb(26, 26, 168);
}

.btn-register {
  color: rgb(255, 255, 255);
  background-color: rgb(26, 26, 168);
}

.blue-block {
  background-color: rgb(26, 26, 168);
  color: white;
  border-radius: 2rem;
}

.block-shadow {
  box-shadow: 5px 5px 5px rgb(160, 160, 160);
}

.algorithm-block {
}

.page-item.active .page-link {
  background-color: rgb(26, 26, 168);
}

.page-link {
  color: rgb(26, 26, 168);
}

.filter-count-label{
  color:rgb(160, 160, 160)
}
`, "",{"version":3,"sources":["webpack://./src/css/main.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;AACA;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;AACF","sourcesContent":[".hidden-copyrightlink {\n  color: black;\n  text-decoration: none;\n}\n\n.hidden-copyrightlink:hover {\n  color: whitesmoke;\n}\n\n.strong {\n  font-weight: 600;\n}\n\n.fab {\n  position: fixed;\n  right: 5%;\n  bottom: 10%;\n  z-index: 999;\n  padding: 1%;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n\n.blue-text {\n  color: rgb(26, 26, 168);\n}\n\n.btn-register:hover {\n  color: rgb(180, 180, 180);\n  background-color: rgb(26, 26, 168);\n}\n\n.btn-register {\n  color: rgb(255, 255, 255);\n  background-color: rgb(26, 26, 168);\n}\n\n.blue-block {\n  background-color: rgb(26, 26, 168);\n  color: white;\n  border-radius: 2rem;\n}\n\n.block-shadow {\n  box-shadow: 5px 5px 5px rgb(160, 160, 160);\n}\n\n.algorithm-block {\n}\n\n.page-item.active .page-link {\n  background-color: rgb(26, 26, 168);\n}\n\n.page-link {\n  color: rgb(26, 26, 168);\n}\n\n.filter-count-label{\n  color:rgb(160, 160, 160)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
