import React from 'react';
const CheckBox = ({ name, label, indent = true, checked = false, onChange, ...rest }) => {
  return (
    <div className={`form-check ${indent ? 'ml-2' : ''}`}>
      <input
        className='form-check-input'
        id={`cb-${label}-${name}`}
        name={label}
        onChange={onChange}
        type='checkbox'
        checked={checked}
        {...rest}
      ></input>
      <label className='form-check-label' htmlFor={`cb-${label}-${name}`}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
