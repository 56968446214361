import React, { useRef, useState } from 'react';
import { HelpIcon } from './helpIcon.jsx';

const Select = ({
  name,
  label,
  options,
  error,
  help,
  indent = false,
  placeholder = 'Selecteer een optie',
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState(placeholder);
  const myRef = useRef(null);

  const preOnChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  const reset = (event) => {
    event.currentTarget = myRef.current;
    event.currentTarget.value = placeholder;
    setValue(placeholder);
    onChange(event);
  };

  return (
    <div className={`form-group position-relative ${indent ? 'ml-2' : ''}`}>
      {value != placeholder && (
        <div className='position-absolute' style={{ right: 0 }}>
          <div className='btn text-muted p-0' onClick={reset}>
            Clear
          </div>
        </div>
      )}
      <label className='' htmlFor={name}>
        <strong>{label}</strong>
      </label>
      {help && <HelpIcon helpText={help} />}
      <select
        ref={myRef}
        name={name}
        id={name}
        {...rest}
        onChange={(event) => preOnChange(event)}
        className='form-control'
      >
        <option value='default' hidden key='placeholder'>
          {placeholder}
        </option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Select;
