import React from 'react';
const Icon = ({ name, mode = 'filled', style = {} }) => {
  let iconName = `${name}`;

  iconName = mode === 'outline' ? iconName + '-outline' : iconName;

  return <ion-icon name={iconName} style={{ ...style }}></ion-icon>;
};

export default Icon;
