import React, { useEffect, useState } from 'react';
import CheckBox from './checkbox';

const MultiCheckBoxList = ({ name, label, options, value, error, onChange, indent = false }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    let newData = {};
    options.forEach((option) => {
      newData[option] = value.indexOf(option) > -1;
    });
    setData(newData);
  }, [value]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newData = { ...data };
    options.forEach((option) => {
      newData[option] = isChecked;
    });
    setData(newData);
    onChange(newData, name);
  };

  const handleChange = (input, option) => {
    const target = input.target;
    let tempData = { ...data };
    tempData[option] = target.checked;
    setData(tempData);
    onChange(tempData, name);
  };

  const handleClear = () => {
    const newData = {};
    options.forEach((option) => {
      newData[option] = false;
    });
    setData(newData);
    onChange(newData, name);
  };

  return (
    <div className={`form-group position-relative ${indent ? 'ml-2' : ''}`}>
      <div className='d-flex'>
        <div>
          <strong>{label}</strong>
        </div>
        {value.length ? (
          <div className='position-absolute' style={{ right: 0 }}>
            <div
              className='btn text-muted ml-auto'
              onClick={handleClear}
            >
              Clear
            </div>
          </div>
        ) : null}
      </div>
      <CheckBox
        name={name}
        label="Selecteer alle"
        checked={Object.values(data).every((isChecked) => isChecked)}
        onChange={handleSelectAll}
        indent={true}
      />
      {options.map((option) => {
        return (
          <CheckBox
            name={name}
            key={option}
            label={option}
            checked={data[option]}
            onChange={(event) => handleChange(event, option)}
            indent={true}
          />
        );
      })}
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default MultiCheckBoxList;