import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Icon from './common/icon.jsx';

const NavBar = ({ user }) => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <Link className='navbar-brand' to='/'>
        AI Inventarisatie
      </Link>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNavAltMarkup'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
        <ul className='navbar-nav'>
          <NavLink className='nav-item nav-link' to='/home'>
            Home
          </NavLink>
          <NavLink className='nav-item nav-link' to='/dashboard'>
            Dashboard
          </NavLink>
          <NavLink className='nav-item nav-link' to='/contact'>
            Contact
          </NavLink>
        </ul>

        <ul className='navbar-nav ml-auto'>
          {user ? (
            <>
              <NavLink
                className='d-flex nav-item nav-link'
                to={`/profile/${user.oid}`}
                title='Check your profile page'
              >
                <Icon
                  name={'person-circle'}
                  mode='outline'
                  style={{ height: '2rem', width: '2rem' }}
                />
                <div className='pt-1'>{user.name ? user.name : user.email}</div>
              </NavLink>
              <NavLink className='nav-item nav-link' to='/logout'>
                <div className='pt-1'>Uitloggen</div>
              </NavLink>
            </>
          ) : (
            <NavLink className='nav-item nav-link' to='/login'>
              Login
            </NavLink>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
