import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className='text-center text-lg-start bg-light text-muted'>
        <section className='pt-4 mt-3 border-top'>
          <div className='container text-center text-md-start mt-5'>
            <div className='row mt-3'>
              <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <i className='fas fa-gem me-3'></i>AI Inventarisatie in de zorg
                </h6>
                <div>Alle AI algoritmes voor de gezondheidszorg op één plek</div>
              </div>
              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                <div className='h6 text-uppercase fw-bold mb-4'>Sitemap</div>
                <div className='text-reset mt-2'>
                  <i className='fa fa-home me-3 mr-2'></i>
                  <Link to='/' style={{ textDecoration: 'none', color: '#6c757d' }}>
                    Home
                  </Link>
                </div>
                <div className='text-reset mt-2'>
                  <i className='fa fa-envelope me-3 mr-2'></i>
                  <Link to='/contact' style={{ textDecoration: 'none', color: '#6c757d' }}>
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='text-center p-2 mt-2' style={{ backgroundColor: 'grey', color: 'white' }}>
          <span>
            © 2023 Copyright:{' '}
            <a
              className='text-light'
              href='https://www.datahub.health/'
              target='_blank'
              rel='noreferrer'
            >
              Datahub, Erasmus MC
            </a>
            <br />
            Ontwikkeld door{' '}
            <a
              className='text-light'
              href='https://www.datasciencelab.nl'
              target='_blank'
              rel='noreferrer'
            >
              Data Science Lab.
            </a>
          </span>
        </div>
      </footer>
    );
  }
}
export default Footer;
