import http from './httpService';

const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/messages`;

function getMessageUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getMessage(id) {
    return http.get(getMessageUrl(id));
}

export function getMessages() {
    return http.get(apiEndpoint);
};

export function postMessage(message) {
    return http.post(apiEndpoint, message);
}