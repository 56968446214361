import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import Algorithm from './components/algorithm.jsx';
import AlgorithmDetail from './components/algorithmDetail.jsx';
import AlgorithmForm from './components/algorithmForm.jsx';
import Footer from './components/common/footer.jsx';
import NavBar from './components/navbar.jsx';
import NotFound from './components/notFound';
import { ContactForm } from './components/pages/contact.jsx';

import Profile from './components/profile';

import Admin from './components/pages/admin.jsx';

import Login from './components/pages/Login.jsx';
import DashBoard from './components/pages/dashboard.jsx';
import auth from './services/authService.js';

import { getAlgorithmDataModel } from './services/algorithmService.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoggedOut from './components/pages/LoggedOut';

const App = () => {
  const [user, setUser] = useState({sub:null,exp:null});
  const [algorithmDataModel, setAlgorithmDataModel] = useState({});

  const location = useLocation();
  const history = useHistory();

  // check if a user is logged in, if not navigate to login page
  useEffect(() => {
    if (
      !location.pathname.includes('/login') &&
      !location.pathname.includes('/logout') &&
      !location.pathname.includes('/logged-out')
    ) {
      const user = auth.getCurrentUser();
      if (user === null) {
        history.push({ pathname: '/login', state: { redirect: true } });
      } else {
        setUser(user);
        getAlgorithmDataModel().then((resp) => {
          setAlgorithmDataModel(resp.data);
        });
      }
    }
  }, [location]);

  
  return (
    <>
      <ToastContainer />
      <NavBar user={user.sub} />
      <main className='flex-grow-1'>
        <Switch>
          <Route path='/login*' render={() => <Login />}></Route>
          <Route path='/logout' render={() => auth.logout()}></Route>
          <Route path='/logged-out' render={() => <LoggedOut />}></Route>
          <Route
            path='/algorithm/:id/detail'
            render={(props) => (
              <AlgorithmDetail algorithmDataModel={algorithmDataModel} {...props} user={user.sub} />
            )}
          ></Route>
          <Route
            path='/algorithm/:id/delete'
            render={(props) => (
              <AlgorithmForm
                algorithmDataModel={algorithmDataModel}
                {...props}
                deleteObject={true}
                user={user.sub}
              />
            )}
          ></Route>
          <Route
            path='/algorithm/:id/edit'
            render={(props) => (
              <AlgorithmForm algorithmDataModel={algorithmDataModel} {...props} user={user.sub} />
            )}
          ></Route>
          <Route
            path='/algorithm/:id'
            exact
            render={(props) => (
              <AlgorithmForm algorithmDataModel={algorithmDataModel} {...props} user={user.sub} />
            )}
          ></Route>
          <Route path='/contact' render={(props) => <ContactForm {...props} user={user.sub} />}></Route>
          <Route path='/profile/:id' render={(props) => <Profile {...props} />}></Route>

          <Route
            path='/admin'
            render={(props) => {
              if (user.sub != null && user.sub.isAdmin) {
                return <Admin {...props} />;
              }
              return <NotFound />;
            }}
          />
          <Route
            path='/home'
            render={(props) => (
              <Algorithm algorithmDataModel={algorithmDataModel} {...props} user={user.sub} />
            )}
          ></Route>
          <Route
            path='/dashboard'
            render={(props) => (
              <DashBoard algorithmDataModel={algorithmDataModel} {...props} user={user.sub} />
            )}
          ></Route>
          <Route path='/not-found' component={NotFound}></Route>
          <Route
            path='/'
            exact
            render={(props) => (
              <Algorithm {...props} user={user.sub} algorithmDataModel={algorithmDataModel} />
            )}
          ></Route>
          <Redirect to='/not-found'></Redirect>
        </Switch>
      </main>
      <Footer />
    </>
  );
};

export default withRouter(App);
