import jwtDecode from 'jwt-decode';
import http from './httpService';

const loginEndpoint = `${process.env.REACT_APP_API_URL}/api/login`;
const tokenKey = 'access_token';
http.setJWT(getJWT());

function loginWithJWT(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem('algorithm');
  window.location.href = '/logged-out';
}

function getJWT() {
  return localStorage.getItem(tokenKey);
}

function getCurrentUser() {
  const jwt = localStorage.getItem(tokenKey);
  try {
    const user = jwtDecode(jwt);
    return user;
    // will return { upn: email, givenName: first name, familyName: last name, isAdmin: bool }
  } catch {
    return null;
  }
}

const getUserByCode = async (code) => {
  try {
    const resp = await fetch(loginEndpoint, {
      method: 'post',
      body: JSON.stringify({ code }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await resp.json();

    const access_token = data['access_token'];
    localStorage.setItem(tokenKey, access_token);
    http.setJWT(access_token);
    return true;
  } catch (e) {
    return false;
  }
};
const auth = {
  logout,
  getCurrentUser,
  loginWithJWT,
  getJWT,
  getUserByCode,
};
export default auth;
