// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0px;
  width: 100%;
  padding: 0;
  background-color: rgb(250, 250, 250);
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page {
  min-height: 50vh;
}

#helperTextContainer {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 500;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,UAAU;EACV,oCAAoC;AACtC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,WAAW;EACX,YAAY;AACd","sourcesContent":["body {\n  margin: 0px;\n  width: 100%;\n  padding: 0;\n  background-color: rgb(250, 250, 250);\n}\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n.page {\n  min-height: 50vh;\n}\n\n#helperTextContainer {\n  position: fixed;\n  top: 0px;\n  width: 100%;\n  z-index: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
