import Joi from 'joi-browser';
import React, { Component } from 'react';
import Input from './input';
import MultiCheckBoxList from './multiCheckBoxList';
import Select from './select';
import TextArea from './textArea';

class Form extends Component {
  state = { data: {}, errors: {} };
  schema = {};

  validate = () => {
    if (Object.keys(this.schema) === 0) return null;

    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) {
      return null;
    }

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  validateProp = (input) => {
    const name = input.name;
    const value = input.value;
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validateMultiSelect = (name, values) => {
    const obj = { [name]: values };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleMultiSelectChange(values, name) {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    let array = Object.keys(values).filter((k) => values[k]); // get keys that are true as array

    const errorMessage = this.validateMultiSelect(name, array);
    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      delete errors[name];
    }
    data[name] = array;
    this.setState({ data });
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProp(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  checkPasswords = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    if (input.value != this.state.data['password']) {
      errors[input.name] = 'Passwords do not match!';
    } else {
      delete errors[input.name];
    }

    this.setState({ errors });
  };

  renderButton(label) {
    return (
      <button className={`btn btn-primary my-1`} onClick={this.handleSubmit}>
        {label}
      </button>
    );
  }

  handleKeyUp(event, submitOnEnter) {
    const { keyCode } = event;
    if (!submitOnEnter || keyCode != 13) {
      return;
    }
    this.handleSubmit(event);
  }

  renderInput(name, label, type = 'text', submitOnEnter = false, indent = false, help) {
    const { data, errors } = this.state;
    return (
      <Input
        label={label}
        type={type}
        value={data[name]}
        name={name}
        onKeyUp={(event) => this.handleKeyUp(event, submitOnEnter)}
        onChange={this.handleChange}
        placeholder={label}
        error={errors[name]}
        indent={indent}
        help={help}
      />
    );
  }

  renderPassword(name, label) {
    const { data, errors } = this.state;
    return (
      <div className='form-group'>
        <Input
          label={label}
          type='password'
          value={data[name]}
          name={name}
          onChange={this.handleChange}
          placeholder={`Type your password here..`}
          error={errors[name]}
        />
        <Input
          label='Please verify your password'
          type='password'
          value={data[name + 'confirm']}
          name={name + 'confirm'}
          onChange={this.checkPasswords}
          placeholder={`Type your password again..`}
          error={errors[name + 'confirm']}
        />
      </div>
    );
  }

  renderTextArea(name, label) {
    const { data, errors } = this.state;
    return (
      <TextArea
        placeholder={`${label}..`}
        className='form-control'
        label={label}
        name={name}
        value={data[name]}
        rows='5'
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderMultiSelect(name, label, options, indent) {
    const { data, errors } = this.state;

    return (
      <MultiCheckBoxList
        name={name}
        label={label}
        options={options}
        value={data[name]}
        // onChange={(event)=>{console.log(event);}}
        onChange={this.handleMultiSelectChange.bind(this)}
        error={errors[name]}
        indent={indent}
      />
    );
  }

  renderSelect(name, label, options, placeholder, indent, help) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        placeholder={placeholder}
        onChange={this.handleChange}
        error={errors[name]}
        indent={indent}
        help={help}
      />
    );
  }

  errorHelper() {
    const { errors } = this.state;
    console.log(errors);
    console.log(errors[0]);
    return <div className='alert alert-danger' role='alert'></div>;
  }
}

export default Form;
