import Joi from 'joi-browser';
import React from 'react';
import { toast } from 'react-toastify';
import Form from '../common/form';

import { postMessage } from '../../services/messageService.js';

class ContactForm extends Form {
  state = {
    data: {
      name: '',
      email: '',
      message: '',
    },
    errors: {},
  };

  setSchema() {
    this.schema = {
      name: Joi.string().required().min(5).max(100).required().label('Naam'),
      email: Joi.string().email().min(5).max(100).required().label('Email'),
      message: Joi.string().min(25).max(2048).required().label('Bericht'),
    };
  }

  async componentDidMount() {
    this.setSchema();
  }

  doSubmit = async () => {
    //call server
    const message = { ...this.state.data };

    try {
      await postMessage(message);
      this.props.history.push('/');
      toast.success(`Bericht succesvol verstuurd. Dank voor uw bericht.`);
    } catch (ex) {
      console.log(ex.response);

      if (ex.response && ex.response.status === 400) {
        toast.error(`Form is invalid: '${ex.response.data}'.`);
      } else {
        toast.error('An unknown error occured.');
      }
    }
  };

  render() {
    // const {data}= this.state;

    return (
      <div className='page'>
        <div className='container'>
          <h1>Neem contact op</h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput('name', 'Naam')}
            {this.renderInput('email', 'E-mailadres')}
            {this.renderTextArea('message', 'Bericht')}
          </form>
          <div className='my-2'></div>
          {this.renderButton('Verstuur')}
          <div className='my-2'></div>
        </div>
      </div>
    );
  }
}

export { ContactForm };
