import http from './httpService';

const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/users`;

export function getUsers() {
  return http.get(apiEndpoint);
}

export function saveUsers(data) {
  return http.put(`/api/users/`, data);
}

export function register(user) {
  return http.post(apiEndpoint, {
    email: user.username,
    password: user.password,
    name: user.name,
  });
}
