import React from 'react';
import { HelpIcon } from './helpIcon.jsx';

const Input = ({
  name,
  label,
  error,
  labelSize = '',
  indent = false,
  help = undefined,
  ...rest
}) => {
  return (
    <div className={`form-group ${indent ? 'ml-2' : ''}`}>
      <label className={labelSize} htmlFor={name}>
        <strong>{label}</strong>
      </label>
      {help && <HelpIcon helpText={help} />}

      <input {...rest} name={name} id={name} className='form-control' />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Input;