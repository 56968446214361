import React, { useEffect,useState } from 'react';
import '../../css/fab.css';
import { Link } from 'react-router-dom';



const FAB = ({items}) =>{
    const [main, setMain]=useState({to:"#"})
        
    useEffect(()=>{
        const getMain = ()=>{
            const main = items.filter(v=>v.isPrimary)[0];
            items.splice(items.indexOf(main), 1)         
            setMain(main);
        }
        getMain();        
    },[items])

    
    return (
        <div className="fab-container">
            <div className="fab shadow" onClick={main.action?main.action:()=>{}}>
                <div className="fab-content" title={main.title}>
                <i className={`fa ${main.iconName}`} aria-hidden="true"></i>
                </div>
            </div>
            {
            items.map((v,i)=>{return(
                <Link key={`${v.iconName}-${i}`} className="sub-button shadow" to={v.to?v.to:'#'} onClick={v.action}>
                    <div className="fab-content" title={v.title}>
                        <i className={`fa ${v.iconName}`} aria-hidden="true"></i>
                    </div>
                </Link>)})
        }
        </div>
    );
}

export default FAB;