import React from 'react';

const TextArea = ({name,label,error,labelSize="",indent=false,...rest}) => {
    return (
            <div className={`form-group ${indent?'ml-2':''}`}>
                <label className={labelSize} htmlFor={name}>{label}</label>
                
                <textarea placeholder={`Enter ${label}..`} 
                    className="form-control" 
                    label={label} 
                    name={name} 
                    rows="5"
                    {...rest}/>
            {error && <div className="alert alert-danger">{error}</div>}
            </div>
            );
}
 
export default TextArea;