import React, { Component, useEffect, useState } from 'react';

import { getUsers, saveUsers } from '../../services/userService.js';
import Table from '../common/table';

import _ from 'lodash';
import { toast } from 'react-toastify';
import Select from '../common/select';

import { getMessages } from '../../services/messageService.js';

class UserList extends Component {
  state = { users: [], sortColumn: { path: 'name', order: 'asc' }, changedUsers: {} };

  async UNSAFE_componentWillMount() {
    const { data: users } = await getUsers();
    this.setState({ users });
    console.log(users);
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  onSelectChange = ({ target }) => {
    const selectedId = target.getAttribute('userid');

    const { users } = { ...this.state };
    let { changedUsers } = { ...this.state };

    for (let user of users) {
      if (user._id === selectedId) {
        if (target.value === 'Teacher') {
          user.isTeacher = true;
          user.isAdmin = false;
        } else if (target.value === 'Admin') {
          user.isAdmin = true;
        } else if (target.value === 'User') {
          user.isTeacher = false;
          user.isAdmin = false;
        }
        changedUsers[selectedId] = { isAdmin: user.isAdmin, isTeacher: user.isTeacher };
        break;
      }
    }
    this.setState({ changedUsers });
  };

  getUserRole(user) {
    if (user.isTeacher) {
      return 'Teacher';
    }
    if (user.isAdmin) {
      return 'Admin';
    }
    return 'User';
  }

  save = async () => {
    const { changedUsers } = this.state;
    try {
      const { data: result } = await saveUsers(changedUsers);
      console.log(result);
      toast.success(`${result['updatedusers']} user(s) were succesfully updated.`);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        toast.error(`Access denied.`);
      } else {
        toast.error(`An error occurred while updating the users.`);
      }
    }
  };

  render() {
    const { users, sortColumn, changedUsers } = this.state;
    const roles = ['User', 'Admin'];
    const sorted = _.orderBy(users, [sortColumn.path], [sortColumn.order]);

    const columns = [
      { path: 'name', label: 'Name' },
      { path: 'email', label: 'E-mail' },
      {
        path: 'isAdmin',
        label: 'Role',
        content: (user) => (
          <Select
            userid={user._id}
            defaultValue={this.getUserRole(user)}
            onChange={this.onSelectChange}
            options={roles}
          ></Select>
        ),
      },
    ];

    return (
      <div>
        <Table columns={columns} data={sorted} sortColumn={sortColumn} onSort={this.handleSort} />
        {Object.keys(changedUsers).length > 0 && (
          <div className='btn btn-primary' onClick={this.save}>
            Save
          </div>
        )}
      </div>
    );
  }
}

const Card = (props) => {
  return (
    <div className={`col-sm-${props.width}`}>
      <div className='mb-3'>
        <div className='card h-100'>
          <div className='card-body'>
            <h6 className='d-flex align-items-center mb-3'>
              <i className='material-icons text-info mr-2'>{props.titlea}</i>
              {props.title}
            </h6>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageBlock = ({ _id, name, email, message, timestamp }) => {
  return (
    <div className='card p-2 my-2' key={_id}>
      <div className='d-flex'>
        <div className=''>Name: {name}</div>
        <div className='mx-auto'>On: {new Date(timestamp).toLocaleString()}</div>
      </div>
      <div className=''>Email: {email}</div>
      <div className=''>Message: {message}</div>
    </div>
  );
};

const Admin = () => {
  const [messages, setMessages] = useState([]);

  useEffect(async () => {
    const { data: messages } = await getMessages();
    setMessages(messages);
  }, []);

  return (
    <div className='container'>
      <h1>Admin</h1>
      <div className='row'>
        <Card titlea='' title='Users'>
          <UserList />
        </Card>
      </div>

      <div>
        <div className='h3'>Messages</div>
        <div>
          {messages.map((m) => {
            return MessageBlock(m);
          })}
        </div>
      </div>
    </div>
  );
};

export default Admin;

Card.defaultProps = {
  titlea: 'My',
  width: 12,
};
